import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

export const IS_CACHE_ENABLED = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache = new Map<string, any>();

  intercept(req: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(IS_CACHE_ENABLED) === true) {
      const cachedResponse = this.cache.get(req.urlWithParams);
      if (cachedResponse) {
        return of(cachedResponse);
      }

      return delegate.handle(req).pipe(
        tap((event) => {
          if (event.type === 4) {
            this.cache.set(req.urlWithParams, event);
          }
        }),
      );
    }

    return delegate.handle(req);
  }
}
